import React, { Fragment } from 'react'
import Link from 'gatsby-link'
import styled from 'styled-components'
import media from 'utils/media-queries'

import { color, fontSize } from 'styles/theme'

import TwoColumns from 'components/twoColumns'
import SectionHeading from 'components/sectionHeading'

const Big = styled.span`
  font-size: ${fontSize.f6};
  color: ${color.grey900};
  font-weight: 700;
  letter-spacing: -0.4px;
  line-height: 1.35;
  ${media.lg`
    font-size: ${fontSize.f5};
    letter-spacing: -0.3px;
  `}
  ${media.sm`
    font-size: ${fontSize.f5};
  `}
`

const About = () => {
  return (
    <TwoColumns
      leftColumn={<SectionHeading>About</SectionHeading>}
      rightColumn={
        <Fragment>
          <Big>Hello! I'm Jessar, a security researcher based in Oslo, Norway.</Big>
          <p>
            I specialize in identifying vulnerabilities in applications. Leveraging my skills, I've hacked into the EU, UN, NASA, Ferrari, Norsk Helsenett, and others.
          </p>
          <p style={{ marginBottom: 0 }}>I'm passionate about reading, drawing, martial arts, and playing the piano.</p>
        </Fragment>
      }
    />
  )
}

export default About
