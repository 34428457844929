import React, { useState } from 'react'
import styled from 'styled-components'
import media from 'utils/media-queries'
import { color, fontSize } from 'styles/theme'
import Icon from 'components/icons'
import "./header.css"

const Div = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex: 0 0 auto;
  ${media.lg`
  height: ${props => (props.article ? '64px' : '')};
  `}
  ${media.sm`
    display: ${props => (props.article ? 'flex' : 'block')};
  `};
  z-index: 10;
  height: ${props => (props.article ? '74px' : '')};
  ${media.xs`
    display: block;
    height: 112px;
  `}
`

const LogoWrapper = styled.div`
  padding: 24px 0 24px 24px;
  ${media.sm`
    padding: ${props => (props.article ? '24px 0 24px 24px' : '24px 0 0 0')};
  `};
  ${media.xs`
    padding: 24px 0 0 0;
  `}
`

const NameLink = styled.a`
  text-decoration: none;
`

const Name = styled.h1`
  white-space: nowrap;
  font-size: ${fontSize.f6};
  text-align: left;
  margin: 0;
  line-height: 1.2;
  letter-spacing: -0.8px;
  ${media.sm`
    text-align: center;
    font-size: ${fontSize.f7};
  `};
`

const NameArticle = styled.div`
  white-space: nowrap;
  font-size: ${fontSize.f5};
  font-weight: 700;
  text-align: left;
  margin: 0;
  line-height: 1.2;
  letter-spacing: -0.6px;
  color: ${color.grey900};
  ${media.xs`
    text-align: center;
  `};
`

const Role = styled.div`
  color: ${color.grey700};
  text-align: left;
  line-height: 1.4;
  font-size: ${fontSize.f4};
  ${media.sm`
    text-align: center;
    font-size: ${fontSize.f6};
  `};
`

const SocialLinks = styled.div`
  display: grid;
  grid-column-gap: 4px;
  grid-template-columns: auto auto auto;
  padding: 15px 24px 0 24px;
  ${media.sm`
    padding: ${props => (props.article ? '15px 12px 0 8px' : '4px 0 0 0')};
    grid-column-gap: 0;
  `}
  ${media.xs`
    padding: 0;
  `}
  justify-content: center;
`

const SocialLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: white;
  border-radius: 20px;
  color: ${color.grey900};
  border: 1px solid white;
  &:hover {
    border: 1px solid ${color.grey150};
    background: ${color.grey150};
    color: ${color.grey900};
  }
  &:active {
    color: ${color.grey900};
  }
  &:visited {
    color: ${color.grey900};
  }
`

export const SvgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  min-height: 24px;
  color: inherit;
  background: inherit;
`

export const InlineSvg = styled.svg`
  height: 24px;
  width: 24px;
  color: inherit;
  fill: currentColor;
`

const Tooltip = styled.div`
  padding: 2px 24px 0 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  opacity: ${props => (props.visible ? '1' : '0')};
  transition: opacity 300ms;
  ${media.sm`
    justify-content: center;
    padding-top: 6px;
  `}
`

const TooltipIcon = styled.div`
  transform: rotate(270deg);
  margin-left: 8px;
`

const TooltipText = styled.div``

const Header = props => {
  const [tooltipIsVisible, setTooltipIsVisible] = useState(false)
  const [tooltipText, setTooltipText] = useState('')

  const showTooltip = tooltipText => {
    setTooltipIsVisible(true)
    setTooltipText(tooltipText)
  }

  const hideTooltip = () => {
    setTooltipIsVisible(false)
  }

  return (
    <Div article={props.article}>
      <LogoWrapper article={props.article}>
        {props.article && (
          <NameLink href="/">
            <NameArticle article={props.article}>Jessar Qais</NameArticle>
          </NameLink>
        )}
        {!props.article && <Name article={props.article}><div id="name">Jessar Qais<a href="#about">

          &nbsp;<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M443.5 162.6l-7.1-7.1c-4.7-4.7-12.3-4.7-17 0L224 351 28.5 155.5c-4.7-4.7-12.3-4.7-17 0l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17l211 211.1c4.7 4.7 12.3 4.7 17 0l211-211.1c4.8-4.7 4.8-12.3.1-17z"></path></svg>

        </a> </div></Name>}
        {!props.article && <Role><div id="subtitle">Security Researcher</div></Role>}
      </LogoWrapper>
      <div>
        <SocialLinks article={props.article}>
          <SocialLink
            href={`javascript:location.href = 'mailto:' + ['contact','jessar.dev'].join('@')`}
            onMouseOver={() => showTooltip('Mail')}
            onFocus={() => showTooltip('Mail')}
            onMouseLeave={hideTooltip}
            onBlur={hideTooltip}
            aria-label="Send an email to Jessar"
          >
            <SvgWrapper>
              <InlineSvg>
                <path d="m22.3592881 5.82427054v.59619016c0 .24378184-.1417446.46531584-.3630886.56747461l-8.9485541 4.13010189c-.6647377.306802-1.4305531.306802-2.0952908 0l-8.94855408-4.13010189c-.22134402-.10215877-.36308864-.32369277-.36308864-.56747461v-.59619016c0-.55012277.44596262-.99608539.9960854-.99608539h18.72640542c.5501228 0 .9960854.44596262.9960854.99608539zm-1.25 13.34754436h-18.21857622c-.69035594 0-1.25-.5596441-1.25-1.25v-8.52329148c0-.20710678.16789322-.375.375-.375.05424403 0 .10784237.01176807.15709707.03449228l8.78345405 4.0523453c.6647826.3067049 1.4305981.306593 2.095291-.0003062l8.7755375-4.05180448c.1880319-.08681729.4108411-.00476666.4976584.18326519.0227541.04928162.0345382.1029156.0345382.1571966v8.52310279c0 .6903559-.559644 1.25-1.25 1.25z" />
              </InlineSvg>
            </SvgWrapper>
          </SocialLink>
          <SocialLink
            href="https://blog.jessar.dev"
            target="blank"
            onMouseOver={() => showTooltip('Blog')}
            onFocus={() => showTooltip('Blog')}
            onMouseLeave={hideTooltip}
            onBlur={hideTooltip}
            aria-label="Jessar's Blog"
          >
            <SvgWrapper>
              <InlineSvg>
                <path d="M 19.59375 13.789062 C 21.082031 12.996094 22.105469 11.359375 22.105469 9.589844 C 22.105469 6.320312 19.652344 4.292969 15.707031 4.292969 L 7.566406 4.292969 C 7.304688 4.292969 7.097656 4.5 7.097656 4.753906 L 7.097656 24.136719 C 7.097656 24.394531 7.304688 24.601562 7.566406 24.601562 L 15.898438 24.601562 C 20.316406 24.601562 23.0625 22.3125 23.0625 18.628906 C 23.0625 16.328125 21.710938 14.5 19.59375 13.789062 Z M 11.324219 7.523438 L 14.609375 7.523438 C 16.6875 7.523438 17.898438 8.542969 17.898438 10.292969 C 17.898438 12.082031 16.605469 13.0625 14.167969 13.0625 L 11.324219 13.0625 Z M 14.90625 21.367188 L 11.324219 21.367188 L 11.324219 15.832031 L 14.769531 15.832031 C 17.433594 15.832031 18.835938 16.777344 18.835938 18.570312 C 18.835938 20.398438 17.472656 21.367188 14.90625 21.367188 Z M 14.90625 21.367188" />
              </InlineSvg>
            </SvgWrapper>
          </SocialLink>
          <SocialLink
            href="https://www.linkedin.com/in/jessarqais"
            target="blank"
            onMouseOver={() => showTooltip('LinkedIn')}
            onFocus={() => showTooltip('LinkedIn')}
            onMouseLeave={hideTooltip}
            onBlur={hideTooltip}
            aria-label="Jessars's linkedin profile"
          >
            <SvgWrapper>
              <InlineSvg>
                <path d="M5.56161 2.73983C5.52952 1.18458 4.48805 0 2.79685 0C1.10565 0 0 1.18458 0 2.73983C0 4.26287 1.07297 5.48156 2.73267 5.48156H2.76427C4.48805 5.48156 5.56161 4.26287 5.56161 2.73983ZM5.23597 7.64654H0.292297V23.5085H5.23597V7.64654ZM18.308 7.76514C21.5611 7.76514 24 10.0327 24 14.9048L23.9998 23.9996H19.0563V15.5134C19.0563 13.3819 18.341 11.9274 16.5513 11.9274C15.1855 11.9274 14.372 12.9079 14.0147 13.855C13.884 14.1944 13.8519 14.6673 13.8519 15.1414V24H8.90765C8.90765 24 8.97281 9.62644 8.90765 8.13803H13.8519V10.3847C14.5081 9.30471 15.6832 7.76514 18.308 7.76514Z" />
              </InlineSvg>
            </SvgWrapper>
          </SocialLink>
        </SocialLinks>
        {!props.article && (
          <Tooltip visible={tooltipIsVisible} article={props.article}>
            <TooltipText>{tooltipText}</TooltipText>
            <TooltipIcon>
              <Icon glyph="arrow" size={24} />
            </TooltipIcon>
          </Tooltip>
        )}
      </div>
    </Div>
  )
}

export default Header
