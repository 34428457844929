import React from 'react'
import styled from 'styled-components'
import media from 'utils/media-queries'

import { fontSize } from 'styles/theme'

import Section from 'components/section'

const FooterText = styled.div`
  text-align: center;
  font-size: ${fontSize.f2};
  ${media.lg`
    font-size: ${fontSize.f1};
  `}
  ${media.sm`
    text-align: left;
    font-size: ${fontSize.f1};
  `}
`

const Footer = () => {
  return (
    <Section>
      <FooterText>
        Made in Norway&nbsp;<a href="https://tools.jessar.dev">
  <svg
    fill="#000000"
    height="20px"
    width="29px"
    viewBox="0 0 442.037 442.037"
    style={{ fill: "#000000" }}
  >
    <path d="M292.076,224.047l59.896-59.896c9.69-9.692,21.926-17.104,35.381-21.433c10.86-3.491,20.9-9.63,29.04-17.758 c14.023-14.03,21.573-32.683,21.258-52.524c-0.314-19.836-8.469-38.252-22.96-51.856l-8.728-8.193L390.1,63.242 c-3.501,11.209-15.475,17.488-26.686,13.996c-8.951-2.795-14.966-10.969-14.966-20.341c0-2.151,0.326-4.285,0.97-6.346L365.193,0 l-11.976,1.945c-13.45,2.184-25.93,8.055-36.093,16.982c-17.856,15.711-26.943,39.167-24.31,62.743 c2.854,25.591-6.265,51.031-25.019,69.794l-24.156,24.148l-43.87-43.87l13.494-13.494l-26.551-26.551 c14.251-20.992,46.044-49.593,75.545-62.687l13.351-5.926l-12.596-7.396C226.284-5.88,179.234,0.458,148.591,31.102 c-2.521,2.521-78.083,78.083-78.083,78.083l-5.25,5.25l5.197,5.303c4.561,4.652,2.924,14.072-3.575,20.571 c-6.499,6.498-15.92,8.135-20.57,3.575l-5.303-5.197L0.502,179.191L76.41,255.1l40.483-40.483l-5.152-5.302 c-4.525-4.656-2.875-14.063,3.604-20.542c6.478-6.479,15.884-8.129,20.541-3.604l5.302,5.153l12.69-12.69l43.87,43.87 l-56.385,56.386c-9.681,9.689-21.912,17.1-35.37,21.431c-11.022,3.543-20.792,9.521-29.038,17.767 c-14.024,14.025-21.576,32.672-21.264,52.507c0.313,19.837,8.468,38.256,22.963,51.864l8.728,8.193l15.868-50.852 c1.69-5.432,5.397-9.878,10.438-12.521c5.04-2.643,10.805-3.165,16.24-1.468c8.954,2.789,14.971,10.955,14.971,20.319 c0,2.129-0.33,4.27-0.98,6.358l-15.76,50.551l11.974-1.946c13.454-2.187,25.933-8.058,36.091-16.982 c17.856-15.711,26.943-39.167,24.31-62.742c-2.854-25.592,6.265-51.032,25.021-69.795l20.633-20.633l139.969,139.969 c6.327,6.327,14.635,9.489,22.945,9.489c8.309-0.001,16.62-3.164,22.946-9.489c12.651-12.652,12.651-33.238,0-45.89L292.076,224.047z" />
  </svg>
</a>
        <br /><a href="#___gatsby" class="to-top">© {new Date().getFullYear()} Jessar Qais <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-up"><polyline points="18 15 12 9 6 15"></polyline></svg></a>
        <br /><a href="https://tools.jessar.dev" class="to-top"></a>    
  </FooterText>
    </Section>
  )
}

export default Footer
